import React, { useState, useEffect } from 'react'
import BackButton from './BackButton';
import { useNavigate } from "react-router-dom";
export default function CoinList() {

    let navigate = useNavigate();
    const [isShowPopup, setPopup] = useState(false);
    const [depositType, setDepsoitType] = useState("");

    const ProceedWarning = (type) => {
        setPopup(true);
        setDepsoitType(type);
    }
    const ProceedToRecharge = () => {
        navigate('../recharge', { replace: true, state: { coinType: depositType } });
    }
    return (
        <>
            <div>
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Deposit Options</span>
                    <span />
                </div>

                <div className="recharge-wrap">
                    <div className='row shadowBox1' style={{backgroundColor:'rgba(36, 116, 54, 0.29)',margin:'16px 5px'}}>
                        <div className='col-5'>
                            <img src='https://cdn3d.iconscout.com/3d/premium/thumb/tron-crypto-coin-9579312-7746691.png?f=webp' className='productImg' style={{ height: '110px', width: '110px' }} alt />
                        </div>
                        <div className='col-7' style={{ fontSize: '0.29rem', padding: '0px' }}>
                            <div ><b style={{ fontSize: 16, color: '#fff' }}>TRX</b></div>
                            <div>
                                <span style={{ fontSize: 13, color:'#ffc62df7' }}>Exchange Rate:<span style={{ paddingLeft: '10px' }}><span style={{ color: '#fff', fontWeight: 'bold' }}>1:1 TRX</span></span></span>
                            </div>
                            <div>
                                <span style={{ fontSize: 13, color:'#ffc62df7' }}>Minimum Deposit:<span style={{ paddingLeft: '10px' }}><span style={{ color: '#fff', fontWeight: 'bold' }}>20 TRX</span></span></span>

                            </div>
                            <div>
                                <button onClick={() => ProceedWarning('TRX')} className='btn btn-info btn-xs' style={{ width: '60%', fontSize: '0.3rem !important', padding: '18px', height: 36, marginTop: '22px', marginBottom: '10px' }}>DEPOSIT</button>
                            </div>
                        </div>
                    </div>
                    <div className='row shadowBox1' style={{backgroundColor:'#54495747',margin:'16px 5px'}}>
                        <div className='col-5'>
                            <img src='https://static.vecteezy.com/system/resources/previews/013/373/699/non_2x/tether-usdt-3d-rendering-isometric-icon-free-png.png' className='productImg' style={{ height: '110px', width: '110px' }} alt />
                        </div>
                        <div className='col-7' style={{ fontSize: '0.29rem', padding: '0px' }}>
                            <div ><b style={{ fontSize: 16, color: '#fff' }}>TRC20-USDT</b></div>
                            <div>
                                <span style={{ fontSize: 13, color:'#ffc62df7' }}>Exchange Rate:<span style={{ paddingLeft: '10px', color: 'blue' }}><span style={{ color: '#fff', fontWeight: 'bold' }}>1:14.54 TRX</span></span></span>

                            </div>
                            <div>
                                <span style={{ fontSize: 13, color:'#ffc62df7' }}>Minimum Deposit:<span style={{ paddingLeft: '10px', color: 'blue' }}><span style={{ color: '#fff', fontWeight: 'bold' }}>3 USDT</span></span></span>

                            </div>
                            <div>
                                <button onClick={() => ProceedWarning('USDT(TRC20)')} className='btn btn-info btn-xs' style={{ width: '60%', fontSize: '0.3rem !important', padding: '18px', height: 36, marginTop: '22px', marginBottom: '10px' }}>DEPOSIT</button>
                            </div>
                        </div>
                    </div>

                    <div className='row shadowBox1' style={{backgroundColor:'#28a7454a',margin:'16px 5px'}}>
                        <div className='col-5'>
                            <img src='https://static.ballet.com/cc-icon/usdt-bep20.png' className='productImg' style={{ height: '110px', width: '110px' }} alt />
                        </div>
                        <div className='col-7' style={{ fontSize: '0.29rem', padding: '0px' }}>
                            <div ><b style={{ fontSize: 16, color: '#fff' }}>BEP20-USDT</b></div>
                            <div>
                                <span style={{ fontSize: 13, color:'#ffc62df7' }}>Exchange Rate:<span style={{ paddingLeft: '10px', color: 'blue' }}><span style={{ color: '#fff', fontWeight: 'bold' }}>1:14.54 TRX</span></span></span>
                            </div>
                            <div>
                                <span style={{ fontSize: 13, color:'#ffc62df7' }}>Minimum Deposit:<span style={{ paddingLeft: '10px', color: 'blue' }}><span style={{ color: '#fff', fontWeight: 'bold' }}>3 USDT</span></span></span>

                            </div>
                            <div>
                                <button onClick={() => ProceedWarning('USDT(BEP20)')} className='btn btn-info btn-xs' style={{ width: '60%', fontSize: '0.3rem !important', padding: '18px', height: 36, marginTop: '22px', marginBottom: '10px' }}>DEPOSIT</button>
                            </div>
                        </div>
                    </div>
                </div>
                {isShowPopup ?
                    <div id="mask">

                        <div className="mask-body" onclick="stopPropagation()" style={{ height: 185, borderRadius: 10, background: '#fff' }}>

                            <i className='fa fa-close crossX' onClick={() => setPopup(false)}></i>

                            <div>
                                <span style={{ fontSize: '16px' }}><span style={{ fontSize: '16px' }} className='badge badge-success'>{depositType}</span></span><br></br>
                                <p className='mymessage'>Check twice before proceeding  to deposit otherwise if you deposit on wrong deposit address then your fund will be lost</p>
                                <button onClick={() => ProceedToRecharge('USDT(BEP20)')} className='btn btn-info btn-xs' style={{ width: '45%', fontSize: '0.3rem !important', padding: '18px', marginLeft: '-24px', height: 36, marginTop: '0px', marginBottom: '20px' }}>PROCEED</button>
                            </div>
                        </div>

                    </div>
                    : null
                }
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    
                    .mymessage{
                        font-size: 15px;
                        line-height: 17px!important;
                        font-style:italic;
                        color:red;
                        margin-right:12%;
                    }
                    .crossX{
                        font-size: 25px;
                        /* position: absolute; */
                        float: right;
                        color: red;
                    }
                    
                    .shadowBox1 {
                        background-color:#fff;
                        box-shadow:none;
                        border-radius:8px;
                        margin:16px 5px;
                        padding:5px;
                    }
                    .btn-info{
                        background-color:#000!important;
                    }
          `
                }}
            />
        </>
    )
}
