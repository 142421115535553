import React from 'react'

export default function NoRecord() {
    return (
        <div className="billddiscription" style={{

             borderRadius: 8, padding: 1, margin: '8px 0px 8px 0px', width: '100%'
        }}>
            <div className='noRecord'>No Record(s) Found</div>
        </div>

    )
}
