import React from 'react'
import QRCode from 'react-qr-code';
import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import toast, { Toaster } from 'react-hot-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BackButton from './BackButton';

export default function Invite() {
    const [value, setValue] = useState("");
    const [userName, setUserName] = useState("");
    const [back, setBack] = useState('#FFFFFF');
    const [fore, setFore] = useState('#000000');
    const [size, setSize] = useState(150);
    useEffect(() => {
        let URL = "";
        let uName = "";
        let xCode = JSON.parse(localStorage.getItem("UserDetails").hexDecode());
        uName = xCode.UserName;
        URL = xCode.ReferralURL;
        setValue(URL + uName);
        setUserName(uName);
    }, []);
    const CopyCallBack = (t, r) => {
        if (r == true) {
            toast.success("Copied");
        }
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title></title>
                <script type='text/javascript' src='https://platform-api.sharethis.com/js/sharethis.js#property=64ff01260beccf00124fb9f4&product=sop' async='async'></script>
            </Helmet>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-center",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.28em',
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff'
                }
            }} /></div>
            <div className="recharge-wrap recharge-wrap2" style={{ minHeight: '100vh' }}>
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Invite your contacts</span>

                </div>
                <div className=" list-wrap3" style={{ margin: 5 }}>
                    <div className="billdeailsnew">
                        <div className="billlist">
                            <p style={{padding: '15px 10PX',margin: 0, fontSize: 15,fontStyle:'italic', color: '#fff', textAlign: 'center' }}>
                                Building your team is a only way to maximize your earnings the more you grow your team the more you earn.
                            </p>
                            <div className="billddiscription" style={{  borderRadius: 5, margin: '10px 15px' }}>
                                <div className="invoce_header" style={{ background: 'rgb(0 0 0 / 20%)', color: '#fff',paddingTop:15,paddingLeft:10,paddingBottom:15,paddingRight:10 }}>
                                    <p style={{ margin: 0, fontSize: 17, color: '#fff', padding: '0px 0', textAlign: 'center' }}>
                                        Build a team and get great rewards
                                        <br></br>
                                        <span className="badge custombadge-warning">✔Level 1- 12%</span>&nbsp;
                                        <span className="badge custombadge-warning">✔Level 2- 2%</span>&nbsp;
                                        <span className="badge custombadge-warning">✔Level 3- 1%</span>
                                    </p>

                                    <p id="refUsername" style={{ margin: 0, fontSize: 17, color: '#fff', padding: '0px 0 15PX', textAlign: 'center', fontWeight: 600 }}>
                                        Invitation Code: <span className="badge badge-success blink_me">{userName}</span>
                                    </p>

                                </div>
                                <div className style={{ textAlign: 'center', padding: '20px 15px 25px' }}>

                                    <QRCode
                                        title="OPX"
                                        value={value}
                                        bgColor={back}
                                        fgColor={fore}
                                        size={size === '' ? 0 : size}
                                    />
                                    <input value={value} className='shareBox' type="text"  id="refLink" />

                                    <CopyToClipboard text={value} onCopy={CopyCallBack}>
                                        <button className="copy_btn" style={{ background: '#282522!important' }}>Copy Link</button>
                                    </CopyToClipboard>
                                    <span style={{ fontSize: '14px' }}>-Share Us-</span>
                                    <br></br>

                                    
                                    <div class="sharethis-inline-share-buttons" data-url={value}></div>


                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="usercard" style={{ marginTop: '-20px' }}>
                        <h4 style={{ margin: '6px 8px', fontSize: 15, color: '#5cb85c' }}>Our 4 Level Recharge Commission
                            Distribution</h4>
                        <div className="userdetail" style={{ background: '#5cb85c', textAlign: 'left', padding: 2, margin: 5, borderRadius: 5, display: 'flex' }}>
                            <div className="userimag">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Tether_USDT.png" style={{ background: '#fff', borderRadius: 50, height: 40, width: 40 }} />
                            </div>
                            <div className="username" style={{ marginLeft: 20 }}>
                                <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>LEVEL 1</p>
                                <h5 style={{ margin: 0, color: '#fff', fontSize: 12 }}>
                                    <span style={{ fontSize: 20 }}>10%</span> </h5>
                            </div>
                        </div>
                        <div className="userdetail" style={{ background: '#5cb85c', padding: 2, margin: 5, borderRadius: 5, display: 'flex' }}>
                            <div className="userimag">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Tether_USDT.png" style={{ background: '#fff', borderRadius: 50, height: 40, width: 40 }} />
                            </div>
                            <div className="username" style={{ marginLeft: 20 }}>
                                <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>LEVEL 2</p>
                                <h5 style={{ margin: 0, color: '#fff', fontSize: 12 }}><span style={{ fontSize: 20 }}>5%</span>.
                                </h5>
                            </div>
                        </div>
                        <div className="userdetail" style={{ background: '#5cb85c', padding: 2, margin: 5, borderRadius: 5, display: 'flex' }}>
                            <div className="userimag">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Tether_USDT.png" style={{ background: '#fff', borderRadius: 50, height: 40, width: 40 }} />
                            </div>
                            <div className="username" style={{ marginLeft: 20 }}>
                                <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>LEVEL 3</p>
                                <h5 style={{ margin: 0, color: '#fff', fontSize: 12 }}><span style={{ fontSize: 20 }}>3%</span>.
                                </h5>
                            </div>
                        </div>
                        <div className="userdetail" style={{ background: '#5cb85c', padding: 2, margin: 5, borderRadius: 5, display: 'flex' }}>
                            <div className="userimag">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Tether_USDT.png" style={{ background: '#fff', borderRadius: 50, height: 40, width: 40 }} />
                            </div>
                            <div className="username" style={{ marginLeft: 20 }}>
                                <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>LEVEL 4</p>
                                <h5 style={{ margin: 0, color: '#fff', fontSize: 12 }}><span style={{ fontSize: 20 }}>1%</span>.
                                </h5>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <style

                dangerouslySetInnerHTML={{
                    __html: `
                    .shareBox{
                    width: 80%;
                    border-radius: 44px;
                    font-size: 12px;
                    border: none;
                    margin-top: 5px;
                    margin-bottom: 10px;
                    margin-left: 0px;
                    /* height: 0.8rem; */
                    background: #ccc;
                    padding-left: 0.4rem;
                    font-weight: 200;
                    padding: 5px 10px;
                    }
                    .custombadge-warning {
                    color: #212529;
                    background-color: #ffc107;
                    padding: 6px 10px;
                    margin: 7px 0px;
                    }
                    .optionnew {
                        padding: 10px;
                        border-bottom: 1px solid #eee;
                    }
                    .blink_me {
                        animation: blinker 1s linear infinite;
                      }
                      
                      @keyframes blinker {
                        50% {
                          opacity: 0;
                        }
                      }
                    .copy_btn {
                        text-decoration: none;
                        font-size: 16px;
                        background: #13B497 !important;
                        padding: 7px 0px;
                        border-radius: 50px;
                        color: #fff;
                        display: block;
                        margin: 0 auto;
                        width: 80%;
                    }
          `
                }}
            />
        </>
    )
}
