import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import Loading from './Loading';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import TransactionsTable from './TransactionsTable';
import { useNavigate, useLocation } from "react-router-dom";

export default function TradeNow() {
    let tvScriptLoadingPromise;
    const onLoadScriptRef = useRef();
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const inputEls = useRef([]);
    const [isLoading, setIsLoading] = useState(false);
    const [walletAmount, setwalletAmount] = useState(0);
    const [tradewalletAmount, settradewalletAmount] = useState(0);
    // const { state } = useLocation();
    // const { symbol } = state;
    const [allValues, setAllValues] = useState({
        InvestmentAmount: 0,
    });
    const [disabled, setDisabled] = useState(false);
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        GetWalletAmount();
        onLoadScriptRef.current = createWidget;

        if (!tvScriptLoadingPromise) {
            tvScriptLoadingPromise = new Promise((resolve) => {
                const script = document.createElement('script');
                script.id = 'tradingview-widget-loading-script';
                script.src = 'https://s3.tradingview.com/tv.js';
                script.type = 'text/javascript';
                script.onload = resolve;

                document.head.appendChild(script);
            });
        }

        tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

        return () => onLoadScriptRef.current = null;

        function createWidget() {
            if (document.getElementById('tradingview_ae93a') && 'TradingView' in window) {
                new window.TradingView.widget({
                    "width": 400,
                    "height": 500,
                    "symbol": "CRYPTOCAP:BTC",
                    "interval": "1",
                    "timezone": "Asia/Kolkata",
                    "theme": "dark",
                    "style": "3",
                    "locale": "in",
                    "toolbar_bg": "#f1f3f6",
                    "enable_publishing": false,
                    "hide_top_toolbar": true,
                    "save_image": false,
                    "container_id": "tradingview_ae93a"
                });
            }
        }
        
    }
    
    , []);
    const GetWalletAmount = () => {
        setIsLoading(true);
        var param = new Object();
        param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.ActionMode = "GetWalletAmount";
        var obj = new Object();
        obj.procName = 'SenderRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setwalletAmount(res[0].WalletAmount);
            var transactions = [],count=10;
            for (let i = 0; i < count; i++) {
                const type = Math.random() > 0.5 ? 'buy' : 'sell';
                const amount = (Math.random() * 10).toFixed(4);
                const price = (Math.random() * 50000).toFixed(2);
                transactions.push({ type, amount, price, date: new Date(new Date().getTime() - Math.random() * 1000000000) });
            }
            console.log(transactions);
          
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    const TradeNow = (tradetype) => {
        toast.success('Our Bot will place best trade orders for you');
        return;
        setIsLoading(true);
        var Obj = new Object();
        Obj.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        Obj.TradeType = tradetype;
        //Obj.Symbol = symbol;
        Obj.ActionMode = "PlaceTradeOrder";
        var objWithdraw = new Object();
        objWithdraw.procName = 'PlaceTradeOrder';
        objWithdraw.Para = JSON.stringify(Obj);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: objWithdraw
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {

                toast.success(res[0].Msg);
                inputEls.current[1].value = "";
            }
            else {
                if (res[0].StatusCode == "0") {
                    toast.error(res[0].Msg);
                } else {
                    toast.error('Something went wrong, plz try again later');
                }
            }
        }).catch(function (error) {
            setIsLoading(false);
        });
       
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Trade</title>
                <link rel="stylesheet" href="css/Recharge.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
            </Helmet>
            
            {isLoading ? <Loading /> : ""}
            <div class="recharge-wrap">


                <div className="list-wrap list-wrap2">
                    <div className='tradingview-widget-container'>
                        <div id="tradingview_ae93a">

                        </div>

                    </div>
                    <span style={{ marginTop: '-15px',fontSize:18,color:'#fff' }}>Our Buy/Sell Trade Logs</span>
                    <TransactionsTable />
                    <div style={{ backgroundColor: '#161A25',height:'288px',borderRadius:5 }}>
                        <i className="fa fa-arrow-left" style={{ color: '#fff', position: 'absolute', fontSize: 20, marginTop: 24, marginLeft: '4%' }} onClick={() => routeChange('market')} />
                        <div className="insurancenew" style={{ display: 'flex', justifyContent: 'space-around', padding: 10, paddingTop: 6 }}>
                            <div className="insuranceone">
                                <h5 style={{ margin: 0, fontSize: 14, color: '#fff' }}>10%</h5>
                                <p style={{ margin: 0, fontSize: 14, color: '#fff' }}>
                                    Signal Ratio
                                </p>
                            </div>
                            <div style={{ borderLeft: '1px solid #161A25' }}>
                            </div>
                            <div className="insuranceone">
                                <h5 style={{ margin: 0, fontSize: 14, color: '#fff' }} className="walletAmount">{walletAmount} TRX</h5>
                                <p style={{ margin: 0, fontSize: 14, color: '#fff' }}>
                                    Wallet
                                </p>
                            </div>
                        </div>
                        <div className style={{ textAlign: 'center', padding: '10px 15px 53px', marginTop: '-35px' }}>
                            <button type="button" className="countbtn decreaseprofitVal">-</button>
                            <input type="number" value={tradewalletAmount} className="figure" id="txtAmount" readOnly/>
                            <button type="button" className="countbtn increaseprofitVal">+</button>
                            <div style={{ display: 'inline' }}>
                                <button type="button" id="btnSubmit" style={{ display: 'inline' }} onClick={() => TradeNow('CALL')} className="call">
                                    <i className="fa fa-arrow-up" style={{ fontSize: 15 }} /> CALL</button>
                                <button type="button" id="btnSubmit" style={{ display: 'inline' }} onClick={() => TradeNow('PUT')} className="put">
                                    <i className="fa fa-arrow-down" style={{ fontSize: 15 }} /> PUT</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .list-wrap.list-wrap2{
                        width:100%!important;
                    }
                    ::placeholder {
                        color: #000 !important;
                        font-family: Microsoft YaHei !important;
                        font-style:italic;
                        font-weight:normal;
                        font-size:15px;
                    }
                    .tv-header__link{
                        display:none;
                    }
                    .lock {
                        font-size: 18px !important;
                        color: #000 !important;
                        position: absolute !important;
                        top: 30px !important;
                        left: 12px !important;
                        z-index: 99 !important;
                    }
                    .myicon{
                        height: 50px;
                        position: absolute;
                        margin-left: 77%;
                        margin-top: -61px;
                      }
                    .BalanceAmt {
                        background-color: #eee;
                        padding: 10px;
                        /* border-radius: 10px; */
                        font-size: 21px;
                        margin-bottom: 20px;
                        /* padding-left: 20px; */
                        border-radius: 50px;
                        margin-top: 15px;
                        width: 89%;
                        margin-left: 5%;
                        text-align: center;
                    }
                    .list-wrap.list-wrap2 .price div {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        height: auto;
                        -webkit-box-align: end;
                        -ms-flex-align: end;
                        align-items: flex-end;
                        font-size: .6rem;
                        -webkit-transform: translateY(-0.22rem);
                        transform: translateY(-0.22rem);
                        margin-right: 11px !important;
                    }
            
                    .list-wrap.list-wrap2 .price {
                        background: #fff;
                        height: auto !important;
                        border-radius: 0.2rem;
                        padding-left: 0.2rem;
                        margin-top: 14px;
                    }
            
                        .list-wrap.list-wrap2 .price div input {
                            width: 90%;
                            font-size: 15px;
                            padding-left: 30px;
                            border: 1px solid #eee !important;
                            border-radius: 8px !important;
                            height: 40px;
                        }
            
                    input:focus {
                        outline: none !important;
                        border-color: #719ECE;
                    }
            
                    .list-wrap .btn {
                        line-height: 42px;
                    }
            
                    .topbg {
                        height: 82px !important;
                        overflow: hidden;
                        background: #ffffff;
                        background-size: 100% auto;
                        color: #FFFFFF;
                        padding-left: 0.26rem;
                    }
            
            
            
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: 44px;
                        background: #4D148C;
                        border-radius: 4px;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0.6rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: all;
                        width: 100%;
                        font-family: Microsoft YaHei !important;
                    }
                    .call {
                        background-color: #28BAA6;
                        color: #fff;
                        border-radius: 5px;
                        padding: 13px;
                        width: 34%;
                        height:40px;
                        font-size: 13px;
                    }
        
                    .put {
                        background-color: #E34E69;
                        color: #fff;
                        border-radius: 5px;
                        padding: 13px;
                        width: 34%;
                        height:40px;
                        font-size: 13px;
                        margin-left:10px;
                    }
        
                    .figure {
                        width: 55%;
                        border-radius: 4px;
                        font-size: 15px;
                        background: aliceblue;
                        border: none;
                        margin-top: 5px;
                        margin-bottom: 10px;
                        margin-left: 0;
                        border: none;
                        height: 0.8rem;
                        border-radius:42px;
                        padding-left: 0.4rem;
                        font-weight: 400;
                        color: #000;
                    }
        
                    .countbtn {
                        padding: 6px;
                        width: 11%;
                        color: #000;
                        font-size: 18px;
                    }
        
                    button {
                        border: none!important;
                    }
        
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
        
                    /* Firefox */
                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                    .tips {
                        font-size: 10px;
    color: #fff !important;
    font-style: normal;
    font-weight:normal !important;
    margin-bottom:10px;
                    }
          `
                }}
            />
        </>
    )
}
