import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const Slider = () => {
    
      const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '200px'
      }
      const slideImages = [
        {
          url: 'https://media.licdn.com/dms/image/D5612AQGCBhizZI81Nw/article-cover_image-shrink_720_1280/0/1709005254024?e=2147483647&v=beta&t=xg83dh9zdfwFGrop09thtYCMCUzyduvfefFTuEmujrA',
        },
        {
          url: 'https://maticz.com/admin/images/blog/how-to-create-a-crypto-trading-bot.jpg',
         
        },
        {
          url: 'https://miro.medium.com/v2/resize:fit:1400/1*Eik47UaBUyMIebmBXQMBPQ.jpeg',
          
        },
      ];

    return (
        <div className="slide-container" style={{borderRadius:'5px'}}>
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                
              </div>
            </div>
          ))} 
        </Slide>
      </div>
    );
};

export default Slider;