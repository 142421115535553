import React from 'react'
import NoRecord from './NoRecord';
export default function IncomeCard(props) {

    return (
        <>
            {props.data != null ? props.data.map((item, i) => {
                const PackageList = (
                    <div className="billddiscription" style={{
                        display: 'flex',backgroundColor:'#000', justifyContent: 'space-between', boxShadow: '#6c757d 2px 3px 5px',
                        borderRadius: 8, padding: 3, margin: '12px 0px', marginLeft: 4, width: '100%'
                    }}>
                        <div><p style={{ margin: '7px 0px', fontSize: 12, color: '#fff' }} className="TransactionNote">
                            <img src="img/logo.png" height="10px" />
                            <span style={{ fontSize: 9, position: 'absolute', marginTop: 5, marginLeft: 7,color:'#fff' }} className="UserMobileNo">{item.TransactionDate}</span>
                            <span style={{ fontSize: 16, position: 'absolute', marginTop: 5, marginLeft: 228,color:'#fff' }} className="UserMobileNo"><span>{item.Amount}</span></span>

                            <span style={{ fontSize: 13, position: 'absolute', marginTop: 25, marginLeft: 230 }} className="UserMobileNo"><span>{item.Status}</span></span>
                        </p>
                            <h6 style={{ margin: '5px 0px', fontSize: 12, color: '#fff',backgroundColor:'#000',padding:'2px' }} className="TransactionDate">{item.TransactionNote}</h6>

                        </div>
                        <div className="billnumber" style={{ marginRight: 7 }}><h3 style={{ margin: '16px 0px', fontSize: 15, color: '#000' }}
                            className="TransactionAmount"></h3></div>

                    </div>
                );
                return PackageList;
            }) : <NoRecord />}
        </>
    );
}
