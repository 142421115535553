import React from 'react'
import { useNavigate } from "react-router-dom";
export default function BackButton(props) {
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    return (
        <div>
            <span onClick={() => routeChange(props.path)} style={{
                width: '.6rem',
                height: '.4rem',
                marginTop:'-14px'
            }}>
                <i className='fa fa-arrow-back' style={{ color: '#fff!important' }} />
            </span>
        </div>
    )
}
