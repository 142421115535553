import React from 'react'
import QRCode from 'react-qr-code';
import { useState, useEffect } from 'react';
import axios from "axios";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Loading from './Loading';
import toast, { Toaster } from 'react-hot-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BackButton from './BackButton';

export default function Deposit() {
    const { state } = useLocation();
    const { coinType } = state;
    const [value, setValue] = useState("");
    const [network, setNetWork] = useState("");
    const [back, setBack] = useState('#FFFFFF');
    const [fore, setFore] = useState('#000000');
    const [size, setSize] = useState(200);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (coinType == "USDT(TRC20)") {
            setNetWork('TRON USDT (TRC-20 Chain)');
        } else if (coinType == "USDT(BEP20)") {
            setNetWork('BSC USDT (BEP20 Chain)');
        } else {
            setNetWork('TRX(TRON)');
        }
        GetDepositAddress();
    }, []);
    const GetDepositAddress = () => {
        setIsLoading(true);
        var param = new Object();
        param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.Type = coinType;
        axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + "GenerateDepositAddress",
            data: param
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setValue(res[0].Msg);
        }).catch(function (error) {
            setIsLoading(false);
        });
    }

    const CopyCallBack = (t, r) => {
        if (r == true) {
            toast.success("Copied");
        }
    }
    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{coinType}</title>
                <link rel="stylesheet" href="css/myteam.css" />
                <link rel="stylesheet" href="css/bootstrap.min.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
                <script src="js/jquery.min.js"></script>
                <script src="js/bootstrap.min.js"></script>
            </Helmet>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-right",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.21em',
                    borderRadius: '1px',
                    background: '#fff',
                    color: '#000'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            <div className="recharge-wrap recharge-wrap2" style={{ minHeight: '110vh' }}>
                <div className="navbar">
                    <BackButton path='depositoptions' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>DEPOSIT</span>

                </div>
                <div className=" list-wrap3" style={{ margin: 5 }}>
                    <div className="billdeailsnew" style={{ marginTop: '-40px' }}>
                        <div className="billlist">
                            <div className="billddiscription" style={{ borderRadius: 0, margin: '55px 15px'}}>
                                <div className="invoce_header" style={{ background: '#ffc107f7', color: '#fff' }}>
                                    <p style={{ margin: 0, fontSize: 17, color: '#000', padding: '0px 0', textAlign: 'center' }}>
                                        Network: {network}
                                    </p>


                                </div>
                                <div className style={{ textAlign: 'center', padding: '10px 15px 25px' }}>
                                    <p style={{ margin: 0, fontSize: 17, color: '#fff', padding: '15px 0', textAlign: 'center' }}>
                                        Scan <b>{network}</b> QR Code
                                    </p>

                                    <QRCode
                                        title="OPX"
                                        value={value}
                                        bgColor={back}
                                        fgColor={fore}
                                        size={size === '' ? 0 : size}
                                    />
                                    <input value={value} type="text" style={{ width: '83%', borderRadius: '0px', fontSize: 12, border: 'none', marginTop: 5, marginBottom: 10, marginLeft: 0, height: '0.8rem', background: '#eee', paddingLeft: '0.4rem', fontWeight: 700, color: '#000!important' }} id="refLink" />

                                    <CopyToClipboard text={value} onCopy={CopyCallBack}>
                                        <button className="copy_btn" style={{ border: 'none' }}>Copy Address & Pay</button>
                                    </CopyToClipboard>
                                    <p style={{ fontSize: 13, fontStyle: 'italic', marginTop: 5, color: '#b58e16' }}>Disclaimer: Please top up only {coinType} to above address
                                        otherwise your deposited fund will be lost and company will not be responsible for this.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .optionnew {
                        padding: 10px;
                        border-bottom: 1px solid #eee;
                    }
                    .blink_me {
                        animation: blinker 1s linear infinite;
                      }
                      
                      @keyframes blinker {
                        50% {
                          opacity: 0;
                        }
                      }
                    .copy_btn {
                        text-decoration: none;
                        font-size: 16px;
                        background: #000 !important;
                        padding: 7px 0px;
                        border-radius: 0px;
                        color: #fff;
                        display: block;
                        margin: 0 auto;
                        width: 70%;
                    }
                    .nav-tabs {
                        display: flex;
                        justify-content: space-evenly;
                    }
                    .nav-tabs>li>a {
                        margin-right: 2px;
                        line-height: 1.42857143;
                        border: 1px solid transparent;
                        border-radius: 4px 4px 0 0;
                        border-bottom: 2px solid #ffc107;
                        color: #000;
                        font-size:17px;
                        padding: 10px;
                    }
                    .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
                        color: #fff;
                        cursor: default;
                        font-weight: bold;
                        border: none;
                        background-color: transparent;
                        border-bottom: 2px solid #007bff;
                        border-bottom-color: #007bff;
                        text-decoration: none;
                        background: #F76300 !important;
                        border-bottom: 2px solid green;
                    }
                    .nav-tabs{
                        border:0px !important;
                    }
                    .nav-tabs li{
                        line-height:20px;
                    }
          `
                }}
            />
        </>
    )
}
