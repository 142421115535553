import React, { useState, useRef } from 'react'
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Loading from './Loading';
import toast, { Toaster } from 'react-hot-toast';
import Lanaguage from './Lanaguage';

export default function Login() {

  let navigate = useNavigate(); // For Navigation Reference
  const inputRefMobile = useRef(); //For Mobile Focus Reference
  const inputRefPassword = useRef();//For Password Focus Reference
  const google = window.google
  //State Management
  const [MobileNo, setMobileNo] = useState('');
  const [Password, setPassword] = useState('');

  //Redirection Method
  const routeChange = (path) => {
    navigate('../' + path, { replace: true });
  };


  //Handling Input Change
  const handleChange = (event) => {
    if (event.target.name == "txtMobileNo") {
      setMobileNo(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  }
  //Handling Loading 
  const [isLoading, setIsLoading] = useState(false);

  //Ajax Calling For Login
  const doLogin = () => {
    if (MobileNo == "") {
      toast.error('Enter Mobile No.')
      inputRefMobile.current.focus();
      return;
    }
    if (MobileNo.toString().length < 10 || MobileNo.toString().length > 15) {
      toast.error('Enter Valid Mobile No.');
      inputRefMobile.current.focus();
      return;
    }
    if (Password == "") {
      toast.error('Enter Password.');
      inputRefPassword.current.focus();
      return;
    }
    setIsLoading(true);
    var obj = {};
    obj = new Object();
    obj.UserId = MobileNo;
    obj.Password = Password;
    var param = new Object();
    param.procName = "SolidityLogin"
    param.para = JSON.stringify(obj);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_WEBAPI_URL,
      data: param
    }).then(function (res) {
      setIsLoading(false);
      res = res.data;
      if (res[0].StatusCode == "1") {
        toast.success('Login Success...');
        var UserDetails = JSON.stringify(res[0]);
        UserDetails = UserDetails.hexEncode();
        localStorage.setItem("UserDetails", UserDetails);
        setTimeout(function () {
          routeChange('home');
        }, 1000)

      } else {
        toast.error(res[0].msg);
      }
    }).catch(function (error) {
      setIsLoading(false);
      console.log(error);
    });
  }
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  //Component Part
  return (
    <>

      <div><Toaster toastOptions={{
        // Define default options
        position: "top-right",
        className: '',
        duration: 5000,
        style: {
          fontSize: '0.21em',
          borderRadius: '1px',
          background: '#fff',
          color: '#000'
        }
      }} /></div>
      {isLoading ? <Loading /> : ""}

      {/* <div className='signinBg'> */}
      <div className="signin-wrap wrap" >

        <Lanaguage></Lanaguage>
        <img onClick={() => window.location.href = 'https://t.me/Manageroptinexai'} src="img/help.png" style={{ height: 55, marginTop: '-39px', float: 'right' }}></img>
        <div style={{ background: '#2f363e', padding: 18, borderRadius: 22, marginTop: '20%' }}>
          <div className="logo mylogo">
            <img src="img/logo.png" />
          </div>



          <ul id="app" style={{ padding: 0, marginBottom: 0, marginTop: 41 }}>
            <li className="item" style={{ lineHeight: '0.88rem' }}>
              <input id="txtMobileNo" style={{ background: '#24292d', color: '#fff' }} ref={inputRefMobile} onChange={handleChange} type="text" name="txtMobileNo" placeholder="Mobile number" />
            </li>
            <li className="item" style={{ lineHeight: '0.88rem' }}>
              <input id="txtPassword" style={{ background: '#24292d', color: '#fff' }} ref={inputRefPassword} onChange={handleChange} type={passwordType} name="txtPassword" placeholder="Password" />
              {passwordType === "password" ? <i className="myeye fa fa-eye-slash" onClick={togglePassword}></i> : <i className="myeye fa fa-eye" onClick={togglePassword}></i>}
            </li>


          </ul>
          <button className="btn btn-info" onClick={doLogin}>Login</button>
          <div style={{ fontSize: 16, boxShadow: 'none!important', color: '#fff', textAlign: 'center', marginTop: '44px' }} onClick={() => routeChange('signup')}>Don't have an account? SignUp</div>
          <button hidden className="btn btn-info regButton" onClick={() => routeChange('signup')}>Register</button>
        </div>
        <div style={{ color: '#fff', padding: 5, fontSize: 13, marginLeft: '0%', textAlign: 'center', marginTop: '67px' }}>
          <img src='img/aibot10.gif' style={{ height: 24, borderRadius: 14 }}></img>
          Copyright 2018-2024 OPTINEXAI - All rights reserved</div>

      </div>
      {/* </div> */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
          ::placeholder {
            color: #fff !important;
            font-family: Microsoft YaHei !important;
        }
        .regButton{
          background-color: #fff!important;
          color: #000 !important;
          border: 1px solid #fff!important;
        }
        .mylogo{
          width:'82%!important';
        }
        .signin-wrap {
          /* width: auto; */
          background: #24292d;
          border-radius: 0px 0px;
          /* overflow: hidden; */
          padding: 10px 20px;
          margin: 10;
          height: 100%;
          position: absolute;
          width: 100%;
      }
      .btn-info.focus, .btn-info:focus{
        box-shadow: none !important;
    }
      .myeye{
        margin-left:88%!important;
        color:#FF8C46;
      }
        i {
            font-size: 18px !important;
            color: #fff !important;
            position: absolute !important;
            top: 12px !important;
            left: 10px !important;
            z-index: 99 !important;
        }

        .navbar {
            max-width: 750px;
            position: fixed;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: .8rem;
            background: #337bd3 !important;
            width: 100%;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            top: 0;
            z-index: 30;
        }

        .navbar span {
            text-align: center;
            font-size: .36rem;
            font-weight: 400;
            color: #ffffff !important;
        }

        input:focus {
            outline: none !important;
            border-color: #719ECE;
        }

        .navbar {
            justify-content: flex-start;
        }

        .btn.register {
            background: #FFFFFF;
            color: #337bd3;
            border: 1px solid #0058AB;
            margin: 0 auto;
        }

        .item {
            width: 5.98rem;
            height: 0.75rem;
            border-radius: 0.14rem;
            margin: 0 auto;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 0.3rem;
            position: relative;
            left: 0px;
            border-radius:50px;
        }

        .logo {
            width: 277px;
            height: auto;
            margin: auto;
            margin-top:3%;
        }

        

        a,
        p {
            font-family: Microsoft YaHei !important;
        }

        .item:nth-child(1) input {
            padding-left: 17px;
        }

        .item:nth-child(2) input {
            padding-left: 17px;
        }       
       
        
        `
        }}
      />
    </>
  )
}
