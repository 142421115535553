import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from "react-router-dom";
import PackageCard from './PackageCard';
import axios from "axios";
import Loading from './Loading';
import Tabs from './Tabs';
import toast, { Toaster } from 'react-hot-toast';
import HomePopup from './HomePopup';
import Lanaguage from './Lanaguage';
import Slider from './Slider';
export default function Home() {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate('../' + path, { replace: true });
  };
  const [isShowPopup, setPopup] = useState(false);
  //Handling Loadin"p
  const [isLoading, setIsLoading] = useState(false);
  const [walletAmount, setwalletAmount] = useState(0);
  const [rechargeAmount, setRechargeAmount] = useState(0);
  useEffect(() => {
    //BindPackage();
    GetWalletAmount();
  }, []);
  const GetWalletAmount = () => {
    setIsLoading(true);
    var param = new Object();
    param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
    param.ActionMode = "GetWalletAmount";
    var obj = new Object();
    obj.procName = 'SenderRegistration';
    obj.Para = JSON.stringify(param);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_WEBAPI_URL,
      data: obj
    }).then(function (res) {
      setIsLoading(false);
      res = res.data;
      setwalletAmount(res[0].WalletAmount);
      setRechargeAmount(res[0].ProductWallet);
    }).catch(function (error) {
      setIsLoading(false);
      console.log(error);
    });
  }

  return (
    <>
      <div><Toaster toastOptions={{
        // Define default options
        position: "top-right",
        className: '',
        duration: 5000,
        style: {
          fontSize: '0.21em',
          borderRadius: '1px',
          background: '#fff',
          color: '#000'
        }
      }} /></div>
      {isLoading ? <Loading /> : ""}
      <div className="lease wrap" style={{ backgroundColor: '#24292d' }}>
        {isShowPopup ?
          <div id="mask">

            <div className="mask-body" onclick="stopPropagation()" style={{ height: 160, borderRadius: 10, background: '#fff' }}>
              <img src="img/popup1.png" style={{ width: '100%' }}></img>
              <i className='fa fa-close closePopup' onClick={() => setPopup(false)}></i>
              <div className='btnPoup'>
                <button><a href='https://t.me/Ultraxmoderator' style={{ color: '#fff' }}>Our Moderator</a></button>
                <button><a href='https://t.me/Agentultrax' style={{ color: '#fff' }}>Our Support</a></button>
              </div>
            </div>

          </div>
          : null
        }
        <div className="banner">
          <div className="row" style={{ height: 50 }}>
            <div className="col-6" style={{ padding: '0px' }}>
              <img src="img/logo.png" style={{ height: '22px', width: 134, borderRadius: '8px', marginTop: '2px', verticalAlign: 'super' }} />
            </div>

            <div className="col-6" style={{ padding: '0px' }}>
              <a href='https://t.me/Manageroptinexai'>
                <img src="img/help.png" className='noblink' style={{ height: '55px', float: 'right' }} />
              </a>
            </div>

          </div>

        </div>


        <div className='row' style={{ marginTop: '5px', borderRadius: '5px' }}>

          <div className='col-12'>
            <Slider />
          </div>

        </div>
        <div className='row newsRow' style={{ borderRadius: '50px', backgroundColor: 'rgba(19, 180, 151, 0.1)', color: '#fff', margin: 0, marginTop: 10 }}>

          <div className='col-2'><img src="img/misc.png" style={{ height: '28px', filter: 'invert(1)' }} /></div>
          <div className='col-10'><marquee style={{ fontSize: 14 }}>OptinexAI Trading platforms employ sophisticated algorithms connected to market data to make trading decisions</marquee></div>


        </div>
        <div className='row'>
          <div className='col-6'>
            <div className='grayblk1'>
              <div style={{ height: '25px', }}><span className='normaltxt'>Trading Profit</span></div>
              <div className='textFigure'><span>{walletAmount} TRX</span></div>
            </div>
          </div>
          <div className='col-6'>
            <div className='grayblk1'>
              <div style={{ height: '25px', }}><span className='normaltxt'>MT5 Wallet</span></div>
              <div className='textFigure'><span>{rechargeAmount} TRX</span></div>
            </div>
          </div>
        </div>
        <div className="row optMenu" style={{ backgroundColor: 'rgba(19, 180, 151, 0.1)', margin: 0, marginTop: 6 }} >
          <div className="col-2" style={{ padding: '0px', marginRight: '8%', marginTop: '-21px' }} onClick={() => routeChange('depositoptions')}>
            <center> <img src="img/botdeposit.png" style={{ width: '71%' }} alt />
              <div style={{ fontSize: '0.26em', color: '#13B497', marginTop: '-11px' }}>Deposit</div>
            </center>

          </div>
          <div className="col-2" style={{ padding: '0px', marginRight: '8%', marginTop: '-21px' }} onClick={() => routeChange('Withdraw')}>
            <center> <img src="img/withdrawalprofit.png" style={{ width: '60%' }} alt />
              <div style={{ fontSize: '0.26em', color: '#13B497', marginTop: '-11px' }}>Withdrawal</div>
            </center>

          </div>
          <div className="col-2" style={{ padding: '0px', marginRight: '8%', marginTop: '-21px' }} onClick={() => routeChange('terminal')}>
            <center> <img src="img/stock-market.png" style={{ width: '60%' }} alt />
              <div style={{ fontSize: '0.26em',background:'#000',borderRadius:12, color: '#d5a300', marginTop: '-11px' }}>Terminal</div>
            </center>

          </div>
          <div className="col-2" style={{ padding: '0px', marginRight: '8%', marginTop: '-21px' }}>
            <a href='https://t.me/optinexaioffical'>
              <center> <img src="img/gram.png" style={{ width: '71%' }} alt />
                <div style={{ fontSize: '0.26em', color: '#13B497', marginTop: '-11px' }}>Group</div>
              </center>
            </a>

          </div>

        </div>

        <div className='row'>
          <div className='col-12'>
            <center><h5 style={{ fontSize: 21, color: '#fff',marginTop:10 }}>OPX AI Trading Bots</h5></center>
            <Tabs />
          </div>
        </div>

      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.nav-item{
  padding-bottom: 10px;
    margin-top: 5px;
    display: none;
}
    .footer-shape {
    position: absolute;
    left: 0;
    bottom: -20px;
    z-index: -1;
    -webkit-animation: FocuS 1s linear 1s infinite;
    animation: FocuS 1s linear 1s infinite;
}
.nav-tabs{
  border-bottom:none;
}
.closePopup{
  position: absolute;
    margin-top: -6%;
    /* margin-right: -28%; */
    color: #fff;
    font-size: 27px;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.blink {
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 2s steps(5, start) infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}
          .l_title h1 {
              font-size: 17px;
              font-weight: 500;
          }

          .lease .item>li {
              background: #ffffff;
              border-radius: 8px;
              position: relative;
              margin-bottom: 0.2rem;
              list-style: none;
          }

          .mask-tips>div .text p {
              text-align: center;
              font-size: .24rem;
              line-height: .39rem;
              color: #ffffff;
              font-weight: 400;
              margin: 1px;
          }

          body {
              background-color: #fff !important;
          }

          .lease {
              /* background: #F5F6F7; */
              min-height: 100vh;
              padding: 0 0.3rem 1.8rem 0.3rem;
              background-color: #24292d !important;
          }

          .mask {
              position: fixed;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 40;
              background: rgba(0, 0, 0, 0.4);
          }

          .mask-body h3 div {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              padding: 0 0.3rem;
              padding-top: 1rem;
          }

          .layui-layer-wrap p {
              font-size: 14px;
          }

          .layui-layer-title {
              background: #e51837;
              ;
              color: #fff;
              border: none;
              text-align: center;
              padding: 0px;
              border-radius: 10px 10px 0px 0px;
          }

          .layui-layer-title {
              padding: 0 80px 0 20px;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #F0F0F0;
              font-size: 14px;
              color: #fff;
              overflow: hidden;
              border-radius: 2px 2px 0 0;
          }

          .announcement-title[data-v-eebac136] {
              font-size: 20px;
              font-weight: 600;
              color: #fff;
              margin-left: 3px;
              margin-bottom: 0.32rem;
              height: 0.64rem;
              line-height: .64rem;
              padding-left: 5px;
              border-bottom: 3px solid #FF6600;
          }

          .lease .notice>ul li {
              width: 33%;
              text-align: center;
              list-style: none;
              line-height: 13px;
          }

          .tesladiv {
              box-shadow: none !important;
              border-radius: 8px;
              margin-right: 5px;
              margin-bottom: 10px;
              display: flex !important;
              background: #fff !important;
              /* flex-direction: row-reverse; */
              justify-content: space-around;
          }

          div.myitem {
              display: flex;
              flex-wrap: wrap;
              height: auto;
              justify-items: center;
              margin: 0px;
              background: #fff;
              box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
          }

          .myl_left img {
              width: 100%;
              height: 4rem;
          }

          .myl_left {
              text-align: end;
          }

          .myl_left {
              width: 100%;
          }
          .lease .banner {
           
          }
          .myl_right {
              padding: 10px 5px;
              width: 100%;
              background: #fff;
              margin-top: -0px;
          }

          .myl_title {
              margin-bottom: 10px;
          }

          .myl_meta {
              /* display: flex;
              width: 100%;
              flex-wrap: wrap; */
          }

          .mymetal {
              width: 50%;
              margin: 0 !important;
              padding: 0 !important;
              -webkit-tap-highlight-color: transparent;
              float: left;
              height: 20px;
          }

          .myl_desc {
              color: #000;
              font-size: 12px;
              vertical-align: top;
          }

          .myday {
              width: 30%;
              font-size: 12px;
              color: #000;
              vertical-align: top;
          }
          .btn-info.focus, .btn-info:focus{
            box-shadow: none !important;
        }

          .myamount {
              width: 30%;
              font-size: 11px;
              color: #000;
              vertical-align: top;
              font-weight:600
          }

          .mystatus_icon {
              height: 35px !important;
              width: 35px !important;
              position: absolute;
              right: 17px;
          }
          `
        }}
      />
    </>
  )
}
