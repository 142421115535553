import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import axios from "axios";
import Loading from './Loading';
import BackButton from './BackButton';
export default function TeamCard() {
    let { levelno } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [teamLevel, setTeamLevel] = useState([]);
    useEffect(() => {
        GetLevelTeam();
    }, []);
    const GetLevelTeam = () => {
        setIsLoading(true);
        var param = new Object();
        param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.Level = levelno;
        param.ActionMode = "GetTeamByLevel";
        var obj = new Object();
        obj.procName = 'AppReportsNew';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setTeamLevel(res);

        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    return (
        <div className="myteam">

            <div className="navbar">
                <BackButton path='team' />
                <span style={{ marginRight: '25%', marginTop: '-15px' }}> Team <a className='badge badge-warning'>{levelno}</a> </span>

            </div>
            {isLoading ? <Loading /> : ""}
            {teamLevel != 'NoRecord' ? teamLevel.map((item, i) => {
                const PackageList = (
                    // <div className="billddiscription" style={{
                    //     display: 'flex', justifyContent: 'space-between', boxShadow: '2px 3px 10px #bab8b8',
                    //     borderRadius: 8, padding: 3, margin: '12px 0px', marginLeft: 4, width: '95%', background: '#4D148C'
                    // }}>
                    //     <div><p style={{ margin: '7px 0px', fontSize: 12, color: '#fff' }} className="TransactionNote">
                    //         <img src="../img/logo.png" height="25px" style={{ borderRadius: 7 }} />
                    //         <span style={{ fontSize: 14, position: 'absolute', marginTop: 5, marginLeft: 7 }} className="UserMobileNo">{item.MobileNo}</span>
                    //         <span style={{ fontSize: 13, position: 'absolute', marginTop: 5, marginLeft: 196 }} className="UserMobileNo">Investment: <span>{item.Investment} USDT</span></span>

                    //         <span style={{ fontSize: 13, position: 'absolute', marginTop: 25, marginLeft: 196 }} className="UserMobileNo">Recharge: <span>{item.Recharge} USDT</span></span>
                    //     </p>
                    //         <h6 style={{ margin: '5px 0px', fontSize: 12, color: '#fff' }} className="TransactionDate">Reg. Date:{item.RegistrationDate}</h6>

                    //     </div>
                    //     <div className="billnumber" style={{ marginRight: 7 }}><h3 style={{ margin: '16px 0px', fontSize: 15, color: '#C38B60' }}
                    //         className="TransactionAmount"></h3></div>

                    // </div>
                    <div className="row shadowBox" style={{boxShadow:'0 0 10px 0 #4950579c',backgroundColor:'#000'}}>
                        <div className="col-4">
                            <img src="https://static.wixstatic.com/media/a89add_3d73f7e43cff4f37bdf0af4772ef6595~mv2.gif" style={{width:'100%',height:'82px',filter:'invert(0)'}} className="productImg" />
                        </div>
                        <div className="col-8" style={{ fontSize: '0.29rem', padding: 0 }}><div>
                            <b style={{ fontSize: 16, color: '#fff' }}>{item.MobileNo}</b></div>
                            <div style={{ color: '#fff' }}><b style={{ marginLeft: '0%', fontSize: 11, color: '#fff' }}>{item.RegistrationDate}</b></div>
                            <div><span style={{ fontSize: 16, verticalAlign: 'baseline', color: '#fff' }}>Bot Amount: </span>
                                <span style={{ color: '#fff', fontSize: 14, fontWeight: 'bold' }}>{item.Investment} TRX</span></div><div>
                                <span style={{ fontSize: 16, verticalAlign: 'baseline', color: '#fff' }}>MT5 Amount: </span>
                                <span style={{ color: '#fff', fontSize: 14, fontWeight: 'bold' }}>{item.Recharge} TRX</span></div><div>

                            </div></div><hr />
                    </div>

                );

                return PackageList;
            }) : <span style={{ width: '25%', marginLeft: '35%', fontSize: 20,color:'#fff' }}>No Records</span>}
        </div>
    );
}
