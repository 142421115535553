import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import BackButton from './BackButton';
import axios from "axios";
import Loading from './Loading';
import toast, { Toaster } from 'react-hot-toast';
export default function ChangePassword() {
    const inputEls = useRef([]);
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const [isLoading, setIsLoading] = useState(false);
    //State Management
    const [allValues, setAllValues] = useState({
        MobileNo: '',
        OldPassword: '',
        Password: '',
        ConfirmPassword: ''
    });
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }

    const UdpatePassword = () => {
        if (allValues.OldPassword == "") {
            toast.error('Enter Old Password');
            inputEls.current[2].focus();
            return;
        }
        if (allValues.Password == "") {
            toast.error('Enter Password.');
            inputEls.current[3].focus();
            return;
        }
        if (allValues.ConfirmPassword == "") {
            toast.error('Enter Confirm Password.');
            inputEls.current[4].focus();
            return;
        }
        setIsLoading(true);
        var regObj = new Object();
        regObj.OldPassword = allValues.OldPassword;
        regObj.Password = allValues.Password;
        regObj.ConfirmPassword = allValues.ConfirmPassword;
        regObj.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        var obj = new Object();
        obj.procName = 'ChangePassword';
        obj.Para = JSON.stringify(regObj);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                localStorage.removeItem('UserDetails');
                toast.success(res[0].msg);
                routeChange("login");
            }
            else {
                toast.error(res[0].msg);
            }
        }).catch(function (error) {
            setIsLoading(false);
        });
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Change Password</title>
                <link href="css/Recharge.css" rel="stylesheet" />
            </Helmet>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-right",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.21em',
                    borderRadius: '1px',
                    background: '#fff',
                    color: '#000'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            <div className="recharge-wrap recharge-wrap2">
                <div className="navbar navbar2">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Change Password</span>

                </div>
                <div className="list-wrap list-wrap3" style={{ background: 'rgba(30, 10, 71, 0.92)' }}>
                <center><img src='https://cdn-icons-png.flaticon.com/512/7960/7960597.png' style={{ height: 140, marginTop: '8%' }}></img></center>
                    <ul className="uls" style={{ padding: 0 }}>
                        {/* <li>
                            <span>Mobile No</span>
                            <input id="MobileNo" ref={(el) => (inputEls.current[1] = el)} onChange={changeHandler} name='MobileNo' type="number" class="form-control" placeholder="Confirm your Mobile No" />
                        </li> */}
                        <li>
                            <span>Old Password</span>
                            <input id="OldPassword" ref={(el) => (inputEls.current[2] = el)} onChange={changeHandler} name='OldPassword' type="Password" class="form-control" placeholder="Enter Old Password" />
                        </li>
                        <li>
                            <span>New Password</span>
                            <input id="Password" ref={(el) => (inputEls.current[3] = el)} onChange={changeHandler} name='Password' type="Password" class="form-control" placeholder="Enter New Password" />
                        </li>
                        <li>
                            <span>Confirm Password</span>
                            <input id="ConfirmPassword" ref={(el) => (inputEls.current[4] = el)} onChange={changeHandler} name='ConfirmPassword' type="Password" class="form-control" placeholder="Enter Confirm Password" />
                        </li>
                    </ul>
                    <button id="Senderreg" onClick={UdpatePassword} class="btn btn-primary sub button1">Update Now
                    </button>
                    <p style={{ fontSize: 13, fontStyle: 'italic', marginTop: 5, color: '#fff' }}>please choose a secure unique password difficult to guess by others.</p>
                    </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .list-wrap.list-wrap3 .uls {
                        display: block;
                        padding-top: 19px !important;
                    }
                    .recharge-wrap2{
                        padding-top:0px!important;
                    }
                    .form-control{
                        font-size:12px;
                    }
                    
                    .list-wrap.list-wrap3 .uls li {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: block;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        height: 1.02rem;
                        border-bottom: none!important;
                        background: transparent;
                        width: 100%;
                        margin-bottom: 17px;
                    }
        
                    .list-wrap.list-wrap3 .uls li span {
                        font-size: .28rem;
                        font-weight: 400;
                        color: #262626;
                        width: 2.5rem;
                        text-align: left;
                        display: none;
                    }
        
                    .list-wrap.list-wrap3 .uls li input {
                        -webkit-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        height: 80%;
                        border: none;
                        font-size: 14px;
                        color: #333;
                        font-weight: 400;
                        background: #eee;
                        width: 100%;
                        padding: 6px 4px;
                        border-radius: 8px;
                    }
        
                    .button1 {
                        background-color: #ff8c46;
                        /* Green */
                        border: none;
                        color: white;
                        padding: 10px 32px;
                        text-align: center;
                        text-decoration: none;
                        display: inline-block;
                        font-size: 16px;
                        width: 100%;
                        font-size: .36rem;
                        font-weight: 400;
                        opacity: 0.5;
                        border-radius: 20px;
                        margin: 0.6rem 0 0.7rem 0;
                    }
        
                    .list-wrap.list-wrap3 .uls li span {
                        font-size: .28rem;
                        font-weight: 400;
                        color: #262626;
                        width: 2.5rem;
                        text-align: left;
                    }
        
                    input:focus {
                        outline: none !important;
                        border-color: #719ECE;
        
                    }
        
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: 0px;
                        background: #49C8FB !important;
                        border-radius: 0.44rem;
                        text-align: center;
                        color: #fff;
                        font-size: .36rem;
                        font-weight: 400;
                        margin: 2px 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: all;
                        width: 100%;
                        border-radius: 4px;
                    }
                    .list-wrap.list-wrap3 .btn{
                        margin-top:-0.7rem!important;
                    }
          `
                }}
            />
        </>
    )
}
