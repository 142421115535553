import React, { useState, useEffect } from 'react'
import BackButton from './BackButton';
import Tabs from './shareTabs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
function Share() {

  const [value, setValue] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    let URL = "";
    let uName = "";
    let xCode = JSON.parse(localStorage.getItem("UserDetails").hexDecode());
    uName = xCode.UserName;
    URL = xCode.ReferralURL;
    setValue(URL + uName);
    setUserName(uName);
  }, []);
  const CopyCallBack = (t, r) => {
    if (r == true) {
      toast.success("Copied");
    }
  }
  return (
    <div>
      <div className="recharge-wrap" style={{ backgroundColor: '#fff', paddingBottom: 80 }}>
        <div className="navbar">
          <BackButton path='home' />
          <span style={{ marginRight: '25%', marginTop: '-15px' }}>Invite Friends</span>

        </div>
        <div className='row'>
          
          <div className='col-10'>
            <div className='invitaionLinkShare'>
              <div style={{ fontSize: '14px' }}><b>Your Invitation Link</b></div>
              <div style={{ fontSize: '12px', height: 35, width: '100%' }}>{value}</div>
              <CopyToClipboard text={value} onCopy={CopyCallBack}>
                <a className='aBtnLink'>Copy the link</a>
              </CopyToClipboard>
            </div>
          </div>
          {/* <div className='col-md-12' style={{ margin: 12 }}>

            <Tabs />
          </div> */}
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .invitaionLinkShare {
            background-color: #f9e4a4;
            border-radius: 11px;
            height: 138px;
            padding: 20px;
            color: #4c4013;
            box-shadow: 11px 5px 15px #004289;
            margin-left: 29px;
        }
                    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
                      color:#fff !important;
                      border-bottom: solid 4px #fff !important;
                    }
                    .nav-tabs{
                      border:0px !important;
                    }
                    .nav-tabs .nav-link{
                      color:#fff !important;
                      padding-bottom:10px !important;
                    }
                    .table td, .table th{
                      padding: 18px!important;
                    }
                    .table{
                      margin-bottom:0px!important;
                    }
          `
        }}
      />
    </div>


  );

}

export default Share;