import React, { useRef, useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import BackButton from './BackButton';
import axios from "axios";
import Loading from './Loading';
import toast, { Toaster } from 'react-hot-toast';

export default function BankCard() {

    const inputEls = useRef([]);
    //Handling Loading 
    const [isLoading, setIsLoading] = useState(false);
    //State Management
    const [allValues, setAllValues] = useState({
        IFSCCode: '',
        Password: '',
        actionMode: 'AddBeneficiary'
    });
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        GetBeneficiary();
    }, []);
    const GetBeneficiary = () => {
        setIsLoading(true);
        var param = new Object();
        param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.ActionMode = "SearchBeneficiary";
        var obj = new Object();
        obj.procName = 'SenderRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res != "") {
                if (res != "NoRecord") {
                    setAllValues({
                        actionMode: "UpdateBeneficiary", IFSCCode: res[0].WalletAddress, Password: ""
                    })
                } else {
                    setAllValues({
                        actionMode: "UpdateBeneficiary", IFSCCode: "", Password: ""
                    })
                }
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }

    const addbeneficiary = () => {
        if (allValues.IFSCCode == "") {
            toast.error('Enter Your TRX Address.');
            inputEls.current[4].focus();
            return;
        }
        if (allValues.Password == "") {
            toast.error('Enter Login Password.');
            inputEls.current[5].focus();
            return;
        }
        setIsLoading(true);
        var regObj = new Object();
        regObj.IFSC = allValues.IFSCCode;
        regObj.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        regObj.Password = allValues.Password;
        regObj.ActionMode = allValues.actionMode;
        var obj = new Object();
        obj.procName = 'SenderRegistration';
        obj.Para = JSON.stringify(regObj);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                toast.success(res[0].Msg);
            }
            else {
                toast.error(res[0].Msg);
            }
        }).catch(function (error) {
            setIsLoading(false);
        });
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Wallet Address</title>
                <link href="css/Recharge.css" rel="stylesheet" />
            </Helmet>
            <div><Toaster toastOptions={{
                // Define default options
                position: "top-right",
                className: '',
                duration: 5000,
                style: {
                    fontSize: '0.21em',
                    borderRadius: '1px',
                    background: '#fff',
                    color: '#000'
                }
            }} /></div>
            {isLoading ? <Loading /> : ""}
            <div class="recharge-wrap recharge-wrap2">
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Withdrawal Wallet</span>

                </div>
                <div className="list-wrap list-wrap3" style={{ background: '#24292d' }}>
                    <center><img src='https://cdn-icons-png.flaticon.com/512/9137/9137767.png' style={{ height: 140, marginTop: '8%' }}></img></center>
                    <ul className="uls" style={{ padding: 0 }}>

                        <li>
                            <span>Enter TRX Address</span>
                            <input name="IFSCCode" value={allValues.IFSCCode}  id="IFSCCode" ref={(el) => (inputEls.current[4] = el)} onChange={changeHandler} class="form-control" placeholder="Enter TRX Address (TRON NETWROK)" />
                        </li>
                        <li>
                            <span>Enter Password</span>
                            <input name="Password" id="Password" ref={(el) => (inputEls.current[5] = el)} onChange={changeHandler} type="password" class="form-control" placeholder="Enter Login Password" />
                        </li>
                    </ul>

                    <button runat="server" id="Senderreg" class="btn btn-primary sub button1" onClick={addbeneficiary}>
                        Update Address
                    </button>
                    <p style={{ fontSize: 13, fontStyle: 'italic', marginTop: 5, color: '#fff' }}>Disclaimer: Please enter valid TRX wallet address after withdrawal it can't be changed.
                </p>
                    </div>
                
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .list-wrap.list-wrap3 .uls {
                        display: block;
                        padding-top: 19px !important;
                    }
        
                    .list-wrap.list-wrap3 .uls li {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: block;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        height: 1.02rem;
                        border-bottom: 1px solid #f5f5f5;
                        background: transparent;
                        width: 100%;
                        margin-bottom: 17px;
                    }
        
                    .list-wrap.list-wrap3 .uls li span {
                        font-size: .28rem;
                        font-weight: 400;
                        color: #fff!important;
                        width: 2.5rem;
                        float:left;
                       
                    }
        
                    .list-wrap.list-wrap3 .uls li input {
                        -webkit-box-flex: 1;
                        -ms-flex: 1;
                        flex: 1;
                        height: 80%;
                        border: none;
                        font-size: 14px;
                        color: #000;
                        font-weight: 400;
                        background: #fff;
                        width: 100%;
                        padding: 6px 4px;
                        border-radius: 8px;
                    }
        
                    .button1 {
                        background-color: rgba(30, 10, 71, 0.92);
                        /* Green */
                        border: none;
                        color: white;
                        padding: 10px 32px;
                        text-align: center;
                        text-decoration: none;
                        display: inline-block;
                        font-size: 16px;
                        width: 100%;
                        font-size: .36rem;
                        font-weight: 400;
                        opacity: 0.5;
                        border-radius: 20px;
                        margin: 0.6rem 0 0.7rem 0;
                    }
        
                    .list-wrap.list-wrap3 .uls li span {
                        font-size: .28rem;
                        font-weight: 400;
                        color: #262626;
                        width: 2.5rem;
                        text-align: left;
                    }
        
                    input:focus {
                        outline: none !important;
                        border-color: #719ECE;
        
                    }
        
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: 0px;
                        background: #49C8FB !important;
                        border-radius: 0.44rem;
                        text-align: center;
                        color: #fff;
                        font-size: .36rem;
                        font-weight: 400;
                        margin: 0.6rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: all;
                        width: 100%;
                        border-radius: 4px;
                    }
                    .recharge-wrap2{
                        padding-top: 0px!important;
                    }
                    .form-control{
                        font-size:14px!important;
                    }
          `
                }}
            />
        </>
    )
}
