import React, { useEffect, useState } from 'react';

const generateTransactions = (count = 10) => {
    const cryptoSymbols = ['BTC', 'ETH', 'LTC', 'XRP', 'BCH', 'ADA', 'DOT', 'BNB', 'USDT', 'DOGE'];
    const transactions = [];

    for (let i = 0; i < count; i++) {
        const type = Math.random() > 0.5 ? 'buy' : 'sell';
        const amount = (Math.random() * 10).toFixed(4);
        const price = (Math.random() * 50000).toFixed(2);
        const symbol = cryptoSymbols[Math.floor(Math.random() * cryptoSymbols.length)];
        transactions.push({
            type,
            amount,
            price,
            symbol,
            date: new Date(new Date().getTime())
        });
    }

    return transactions;
};

const TransactionsTable = () => {
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        // Generate initial transactions
        setTransactions(generateTransactions());

        // Generate new transactions every 5 seconds
        const interval = setInterval(() => {
            setTransactions(prevTransactions => [
                ...generateTransactions(1),
                ...prevTransactions,
            ]);
        }, 10000);

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <table className="transactions-table" style={{ fontSize: 12 }}>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Symbol</th>
                        <th>Amount</th>
                        <th>Price</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((transaction, index) => (
                        <tr key={index} className={`transaction ${transaction.type}`}>
                            <td>{transaction.type.toString().toUpperCase()}</td>
                            <td>{transaction.symbol}</td>
                            <td>{transaction.amount}</td>
                            <td>${transaction.price}</td>
                            <td>{transaction.date.toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
         .transactions-table {
  width: 100%;
  border-collapse: collapse;
}

.transactions-table th, .transactions-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.transactions-table th {
  background-color: #f2f2f2;
}

.transaction {
  animation: fadeIn 0.5s ease-in-out;
}

.transaction.buy {
  background-color: #e0f7e0; /* Light green for buy */
  animation: buyFadeIn 0.5s ease-in-out;
}

.transaction.sell {
  background-color: #f7e0e0; /* Light red for sell */
  animation: sellFadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes buyFadeIn {
  from { opacity: 0; transform: translateX(-20px); }
  to { opacity: 1; transform: translateX(0); }
}

@keyframes sellFadeIn {
  from { opacity: 0; transform: translateX(20px); }
  to { opacity: 1; transform: translateX(0); }
}
`
                }}
            />
        </>
    );
};

export default TransactionsTable;
