import React from 'react'
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import BackButton from './BackButton';
import CryptoWidget from './CryptoWidget';
export default function AboutCompany(props) {
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    return (
        <div>
             <Helmet>
             <script src="https://price-static.crypto.com/latest/public/static/widget/index.js"></script>
            </Helmet>
             <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>About Company</span>

                </div>
            <div className='col-12'>
                <div className='row'>
                    
                    <div className='col-12'>
                   
                        <div>
                            <center> <img src="img/about.png" style={{ width: '100%', marginBottom: 20 }} alt /></center>
                        </div>
                        <p style={{ fontSize: '0.26em', marginBottom: '10px', color: '#fff' }}>
                            Unlock the cosmos of crypto possibilities with OptinexAI – Your Gateway to Limitless Trading Horizons. Seamlessly navigate the galaxies of digital assets, where innovation meets opportunity. Join OptinexAI, where trades transcend boundaries.OptinexAI Robots trade 24x7 ! The world of Optinex Trading AI ROBOT, where advanced artificial intelligence technology revolutionizes the way we trade.Discover the power of automation and precision in the financial markets OptinexAI.

                            We Have The Best Algorithm By Which We Trade More accurately and make best trades out of it. Algorithmic trading can provide a more systematic and disciplined approach to trading, which can help traders to identify and execute trades more efficiently than a human trader could.Imagine a world where you dont have to go through any type of technical or Fundamental Analysis just have to print dollars with the help of OptinexAI technology.

                            The OptinexAI ROBOT operates tirelessly around the clock, taking advantage of global trading opportunities, even when you sleep. Eliminate emotional biases from your trading decisions as the OptinexAI ROBOT executes trades based on data-driven analysis and predefined rules.The OptinexAI ROBOT's advanced algorithms and lightning fast execution ensure precise and timely trade entries and exits, increasing overall accuracy.
                        </p>

                    </div>
                </div>
                <div ><b style={{ fontSize: 16, color: '#fff' }}>TOP COIN LIST</b></div>
                <CryptoWidget />
            </div>
            <div>

            </div>
        </div>
    )
}
