import React, { useEffect } from 'react';

const CryptoWidget = () => {
    useEffect(() => {
        // Ensure the script has been loaded before initializing the widget
        if (window.CryptoWidget) {
            window.CryptoWidget.init();
        }
    }, []);

    return (
        <>
            <div id="crypto-widget-CoinList" style={{background:'#212529'}} data-theme="dark" data-design="modern" data-coin-ids="1,418,136,382,29,166"></div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    
                    .css-1g6d0u3{
                    background: #212529;
                    }
          `
                }}
            />
        </>
    );
};

export default CryptoWidget;
