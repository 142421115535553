import React, { useState, useEffect, useRef } from 'react'
function TabDataFlexible(props) {
  const inputEls = useRef([]);

  return (
    <div style={{marginTop:-27}}>
      {props.data.map((item, i) => {
        const PackageList = (
          <div className="shadowBox mb-xl-0">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-xl-6 col-12 text-center">
                <div className="row d-flex align-items-center">
                  <div className="col-xl-6"><img style={{ maxWidth: 200 }} src={`img/${item.PackageImage}`} className="img-fluid" /></div>
                  <div className="col-xl-6">
                    <h2 className="text-white" style={{ fontSize: 18, color: '#fff',marginBottom:0 }}>{item.PackageName}</h2>
                    <p className="counter text-danger mx-2" style={{ fontSize: 18, color: '#fff',marginBottom:0 }}>
                    {item.Amount}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-12 text-center" style={{marginTop:'-12%'}}>
                <span className="text-white" style={{ fontSize: 18, color: '#fff' }}>Generates Daily</span>
                <h4 className="counter text-success mx-2" style={{ fontSize: 18,marginBottom:0 }}>
                  {item.DailyReturnPercentage}% Returns
                </h4>
                <p style={{ fontSize: 14, color: '#fff',fontStyle:'italic',marginBottom:10 }}>{item.Description}<br />
                  <span className="text-white">Bot Cycle: <span className="text-danger">{item.ReturnDays} Days</span>
                  </span>
                </p>
              </div>
            </div>
           
          </div>

        );
        return PackageList;
      })}
    </div>
  );
}

export default TabDataFlexible;